import React from "react"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { FluidImageFile } from "../types/images"
import SEO from "../components/SEO"

interface IndexProps {
  data: {
    illustration: FluidImageFile
  }
}

const Index = ({ data }: IndexProps) => (
  <Layout>
    <SEO />
    <section className="mx-auto max-w-screen-xl container py-12 px-4">
      <div className="flex flex-wrap items-center text-center -mx-2 lg:text-left">
        <div className="px-2 mt-10 order-1 lg:w-1/2 lg:pr-10 lg:mt-0 lg:order-none">
          <h2 className="text-2xl leading-tight font-heading">
            Hello. My name is
          </h2>
          <h1 className="text-7xl black leading-tight font-heading">
            Isadora Maria
          </h1>
          <h2 className="text-2xl leading-tight font-heading">
            a.k.a. Isa, Dora, Dorinha,
          </h2>
          <h2 className="text-2xl leading-tight font-heading">
            Dora Maria &amp;&amp; Dona Maria...
          </h2>
          <div className="my-12"></div>
          <p className="text-gray-500 leading-relaxed">
            I am a Brazilian Program Manager living in Canada, moving to the
            USA!
            <br />
            For over 10 years, I'm in love with the same things: web
            development, UI/UX, great design, AI, psychology, and music. Not in
            order necessarily. I love it all.
            <br />
            My biggest passion in developing software products is to make things
            obvious to users. I love making great software with great user
            interfaces and experiences that users love and never stop using
            because they get what they need with great design.
            <br />
            My second biggest passion is to share everything that I know. I love
            sharing about things that were not easy for me to learn or
            understand so that other people can do the same without stressing so
            much. I can talk pretty much about everything: career, psychology,
            technology, personal life, relationships. That's mostly why I also
            work as a career mentor during the weekends as a volunteer. Now I
            walk towards a coach certification in the United States, and to
            scale my sharing, I started a project on Instagram that reached over
            15 thousand followers during its first three months. Check it out
            @empoderadoramaria (in Portuguese BR)!
            <br />
            I'm also in love with mindfulness practices. I am always reading a
            book about neuroscience, psychology (did I say this before!?),
            emotional intelligence, and leadership tools to bring out the best
            of my teams and me.
            <br />
            In my spare time (‼️) I take opera singing classes and take my
            master's at Harvard University. As a design-engineer Unicorn 🦄, I
            chose Digital Media Design to maximize my hours working with
            technology and design.
            <br />
            If you have the same passions, let's have a coffee together! Reach
            me in one of my social media options — check the icons on the top of
            this page😊.
          </p>
        </div>
        <div className="px-2 lg:w-1/2">
          <Img
            fluid={data.illustration.childImageSharp.fluid}
            alt={"Isadora's Illustration"}
          />
        </div>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    illustration: file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Index
