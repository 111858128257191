import React from "react";
import Nav from "./Nav";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <>
    <div className="max-w-screen-xl container mx-auto px-4">
      <Nav />
    </div>
    <main className="w-full">
      {children}
    </main>
  </>
);

export default Layout;
