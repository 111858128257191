import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FixedImageFile } from "../types/images";
import Img from "gatsby-image"


interface NavQueryData {
  instagramImage: FixedImageFile;
  linkedinImage: FixedImageFile;
}


const navQuery = graphql`
  query {
    instagramImage: file(relativePath: { eq: "social/instagram.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    linkedinImage: file(relativePath: { eq: "social/linkedin.png" }) {
      childImageSharp {
        fixed(width: 24, height: 24) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }    
  }
`;


const Nav = () => {
  const data = useStaticQuery<NavQueryData>(navQuery);
  
  return (<nav role="navigation" className="p-4">
    <ul className="flex justify-start items-center mt-4 space-x-4 lg:mt-0">
      <li>
        <Link to="https://www.instagram.com/empoderadoramaria/">
          <Img fixed={data.instagramImage.childImageSharp.fixed} alt="Follow me on Instagram" />
        </Link>
      </li>
      <li>
        <Link to="https://www.linkedin.com/in/isamendes/">
          <Img fixed={data.linkedinImage.childImageSharp.fixed} alt="Connect with me on LinkedIn" />
        </Link>
      </li>      
    </ul>
  </nav>)
}

export default Nav;
